import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  styled,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import phoneCodigo from "../../../assets/OTP.gif";
import { Alert } from "../../../components/Alert";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../services/AuthService";
import { LoadingButton } from "@mui/lab";
// import { makeStyles } from "@mui/styles";
import { InputCustomField } from "../../../components/InputCustomField";
import MaskCodigo from "../../../components/MaskCodigo";

const Image = styled("img")(({ theme }) => ({
  height: 277,
  margin: "0 auto",
  [theme.breakpoints.down(1400)]: {
    height: 220,
  },
  [theme.breakpoints.down("sm")]: {
    height: 300,
  },
  [theme.breakpoints.down("xs")]: {
    height: 150,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

// const styles = makeStyles((theme) => ({
//   rootPIN: {
//     width: '100% !important',
//     marginTop: '2% !important',
//     opacity: 1,
//     borderRadius: 6,
//     backgroundColor: '#fff',
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     paddingTop: '5px !important',
//     paddingBottom: '5px !important',
//     marginBottom: '7px !important',
//     "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
//       // borderColor: "green",
//       letterSpacing: '5px !important',
//       fontSize: '23px !important',

//     },
//     "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-input": {
//       // borderColor: "red",
//       letterSpacing: '5px !important',
//       fontSize: '23px !important',
//     },
//     "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
//       // borderColor: "purple",
//       letterSpacing: '5px !important',
//       fontSize: '23px !important',
//     },
//   },
// }));

export default function SecurityCode() {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [reloadSing, setReloadSing] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [estadoCorrecto, setEstadoCorrecto] = useState(false);

  const userPass = JSON.parse(sessionStorage.getItem("userPass"));
  const [seconds, setSeconds] = useState(59);
  const [minutes, setMinutes] = useState(0);

  function updateTime() {
    if (minutes === 0 && seconds === 0) {
      setSeconds(0);
      setMinutes(0);
    } else {
      if (seconds === 0) {
        setMinutes((minutes) => minutes - 1);
        setSeconds(59);
      } else {
        setSeconds((seconds) => seconds - 1);
      }
    }
  }

  useEffect(() => {
    const token = setTimeout(updateTime, 1000);
    return function cleanUp() {
      clearTimeout(token);
    };
  });

  const cerrarSesion = () => {
    sessionStorage.clear();
    navigate("/auth/login");
  };

  const reenviarCodigo = async () => {
    setLoadingButton(true);
    try {
      const authService = AuthService.getInstance();
      const resetPass = await authService.resetPassword({
        UserName: userPass.UserName,
        NewPassword: userPass.NewPassword,
      });
      if (resetPass.data.data.Body) {
        Alert({
          icon: "success",
          text: "Se ha enviado un código de verificación a tu cuenta de correo electrónico.",
        });
        setLoadingButton(false);
        // navigate("/inbox/securityCode");
      } else {
        Alert({
          icon: "error",
          title: "Error",
          text: "Hubo un error. " + resetPass.data.data.Messages,
        });
        setLoadingButton(false);
      }
    } catch (error) {
      setLoadingButton(false);
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error,
      });
    }
  };

  const handleClick = (estado) => {
    setOpen(!open);
    setEstadoCorrecto(estado);
  };

  const handleService = async (data) => {
    setReloadSing(true);
    console.log(userPass)
    console.log('DATAPIN', data, userPass.UserName)
    try {
      const authService = AuthService.getInstance();
      const validatePIN = await authService.validatePIN({
        UserName: userPass.UserName,
        PIN: data,
      });
      console.log('validatePIN', validatePIN)
      if (validatePIN.data.data.Body) {
        setReloadSing(false);
        Alert({
          icon: "success",
          text: "Se ha actualizado tu contraseña con éxito.",
        });
        cerrarSesion();
      } else {
        setReloadSing(false);
        Alert({
          icon: "error",
          title: "Error",
          text: "Hubo un error. " + validatePIN.data.data.Messages,
        });
      }
    } catch (error) {
      console.log(error)
      setReloadSing(false);
      Alert({
        icon: "error",
        title: "Error",
        text: `Hubo un error. ${error}`,
      });
    }
  };

  return (
    <Fragment>
      <Grid container justifyContent={"center"} style={{ height: '100%' }}>
        <Grid item lg={3} sm={5} md={7} xs={12} style={{ height: '100%' }}>
          <form onSubmit={handleSubmit(handleService)}>
            <Box>
              <Box sx={{ p: 1, textAlign: "center" }}>
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  Código de seguridad
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 1,
                }}
              >
                <Typography textAlign={"justify"}>
                  Introduce el código que hemos enviado al correo electrónico
                  asociado a la cuenta del usuario <b>{userPass?.UserName}</b>
                </Typography>
              </Box>
              <Box>
                <Image alt="Celular codigo" src={phoneCodigo} />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', p: 2 }}>
                <Box sx={{ backgroundColor: "secondary.main20", p: 1 }}>
                  <Typography variant="caption">
                    Tiempo para reenvío: 0{minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <MaskCodigo
                  funcionClick={handleService} reloadSing={reloadSing}
                />
              </Box>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Box sx={{ mt: 1, textAlign: "center" }}>
                <LoadingButton
                  fullWidth
                  loading={loadingButton}
                  variant="outlined"
                  disabled={seconds === 0 ? false : true}
                  sx={{
                    fontSize: "14px !important",
                    height: 38,
                  }}
                  color="secondary"
                  onClick={() => reenviarCodigo()}
                >
                  <Typography sx={{ letterSpacing: 1.2 }}>Reenviar código</Typography>
                </LoadingButton>
              </Box>
              <Box sx={{ mt: 1, textAlign: "center" }}>
                <LoadingButton
                  fullWidth
                  sx={{ height: 38 }}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={() => {
                    sessionStorage.removeItem('userPass');
                    navigate("/inbox/files")
                  }}
                >
                  <Typography sx={{ letterSpacing: 1.2 }}>Cancelar</Typography>
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={"xs"}
        onClose={handleClick}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h5">
              {estadoCorrecto
                ? "Código verificado"
                : "Datos introducidos no coinciden"}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center", p: 2 }}>
            <Typography sx={{ fontSize: 18 }}>
              {estadoCorrecto
                ? "Ahora puedes iniciar sesión con tu nueva contraseña"
                : "La dirección de correo ----- no se encuentra registrada, vuelve a intentarlo y si no tienes una cuenta en DocSolutions, puedes registrarte ahora"}
            </Typography>
          </Box>
          <Box sx={{ p: 1, borderTop: 1, borderColor: "secondary.main60" }}>
            {estadoCorrecto ? (
              <Button
                fullWidth
                onClick={() => {
                  handleClick();
                  navigate("/");
                }}
                sx={{ fontSize: 16 }}
              >
                Iniciar sesión
              </Button>
            ) : (
              <Box sx={{ display: "flex" }}>
                <Button fullWidth onClick={handleClick} sx={{ fontSize: 14 }}>
                  Reintentar
                </Button>
                <Button fullWidth onClick={handleClick} sx={{ fontSize: 14 }}>
                  Registrarse
                </Button>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
